import React, { useEffect, useState } from 'react';
import './App.css';
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, CssBaseline, CircularProgress } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
import firebaseUtil from './firebaseUtil';
import { dbRegisterAutoLogin, registerIp } from './dbUtil';
import publicIp from 'public-ip'
import { CookiesProvider } from 'react-cookie';


const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: grey['A300'],
  },
  status: {
    danger: 'orange',
  },
});
function App() {

  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  useEffect(() => {
    console.log('Hi dev :)')

    firebaseUtil.isInitialized().then(val => {
      setFirebaseInitialized(val)
      if (val) {
        dbRegisterAutoLogin(val)
      }

      publicIp.v4().then(ip => {
        // console.log('ip', ip);
        registerIp({ ip, date: new Date(), userUid: val ? val.uid : null })
      })
    })


  }, [])

  return firebaseInitialized !== false ? (<ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <CookiesProvider>
        <div className="app">
          {/* <Nav /> */}
          <Routes />
        </div>
      </CookiesProvider>
    </BrowserRouter>
  </ThemeProvider>
  ) : <div className="loader"><CircularProgress /></div>
}

export default App;
