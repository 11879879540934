import React, { useState, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import firebaseUtil from '../firebaseUtil'

export default function Routes() {
    const [redirectData, setRedirectData] = useState(undefined)
    const { pathname } = useLocation();

    let Home = () => <div>home</div>
    let SemRota = () => <div>SemRota</div>

    useEffect(() => {
        let observer = null;
        console.log('pathname', pathname)
        let rota = pathname.substring(1, pathname.length)
        console.log('rota', rota)
        if (rota)
            firebaseUtil.db.collection('redirect').doc(rota).onSnapshot((doc) => {
                let redirectData = doc.data();
                if (redirectData) {
                    setRedirectData(redirectData)
                    window.location.replace(redirectData.url)
                } else {
                    setRedirectData(null)
                }
            })
        return () => {
            if (observer) observer();
        }
    }, [pathname])

    return (

        <Switch>

            <Route path='/' component={Home} exact />
            {redirectData === undefined ? <div>loading...</div> :
                <div> {redirectData ? "go to:" + redirectData.url : <SemRota />}</div>
            }

        </Switch>

    )
}
